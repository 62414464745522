<template>
    <div>
        <b-row>
            <b-col cols="12" md="4" lg="3">
                <b-form-group :label="$t('code')">
                    <div class="label-as-input">{{ isNotNullValue(formData.code) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" lg="9">
                <b-form-group :label="$t('name')">
                    <b-input-group prepend="TR" class="mb-1">
                        <div class="label-as-input">
                            {{ isNotNullValue(formData.name) }}
                        </div>
                        </b-input-group>
                    <b-input-group prepend="EN"><div class="label-as-input">{{ isNotNullValue(formData.name_en) }}</div></b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
                <b-form-group :label="$t('short_name')">
                    <b-input-group prepend="EN"><div class="label-as-input">{{ isNotNullValue(formData.short_name) }}</div></b-input-group>
                        <b-input-group prepend="EN"><div class="label-as-input">{{ isNotNullValue(formData.short_name_en) }}</div></b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
                <b-form-group :label="$t('diploma_name')">
                        <b-input-group prepend="EN"><div class="label-as-input">{{ isNotNullValue(formData.diploma_name) }}</div></b-input-group>
                        <b-input-group prepend="EN"><div class="label-as-input">{{ isNotNullValue(formData.diploma_name_en) }}</div></b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
                <b-form-group :label="$t('card_name')">
                        <b-input-group prepend="TR"><div class="label-as-input">{{ isNotNullValue(formData.card_name) }}</div></b-input-group>
                        <b-input-group prepend="EN"><div class="label-as-input">{{ isNotNullValue(formData.card_name_en) }}</div></b-input-group>

                </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
                <b-form-group :label="$t('dean_title')">

                        <b-input-group prepend="EN"><div class="label-as-input">{{ isNotNullValue(formData.dean_title) }}</div></b-input-group>
                        <b-input-group prepend="EN"><div class="label-as-input">{{ isNotNullValue(formData.dean_title_en) }}</div></b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                    <b-form-group :label="$t('level')">
                        <b-input-group prepend="EN"><div class="label-as-input">{{ isNotNullValue(formData.dean_title_en) }}</div></b-input-group>
                    </b-form-group>
            </b-col>
            <b-col>

                    <b-form-group :label="$t('credit_limit')">
                        <b-input-group prepend="EN"><div class="label-as-input">{{ isNotNullValue(formData.credit_limit) }}</div></b-input-group>
                    </b-form-group>
            </b-col>
            <b-col>
                    <b-form-group :label="$t('dean')">
                        <b-input-group prepend="EN"><div class="label-as-input">{{ isNotNullValue(formData.dean.person.name)+' '+isNotNullValue(formData.dean.person.surname) }}</div></b-input-group>
                    </b-form-group>
            </b-col>

            <b-col>
                    <b-form-group :label="$t('campus')">
                        <b-input-group prepend="EN"><div class="label-as-input">{{ isNotNullValue(formData.campus) }}</div></b-input-group>
                    </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    // Services
    import FacultyService from '@/services/FacultyService';

    export default {
        components: {
            FacultyService
        },
        props: {
            formId: {
                type: String
            }
        },
        data() {
            return {
                formData: {},
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                FacultyService.get(id)
                              .then(response => {
                                  this.formData = response.data.data;
                              })
                              .catch(e => {
                                  this.showErrors(e)
                              })
            }
        }
    }
</script>
